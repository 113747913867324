<template>
    <footer role="contentinfo" v-show="!this.$store.state.dotLoader" >
        <v-container fluid class="pb-0">
            <v-col class="ml-4">
                <v-btn icon aria-label="Exit/Logout Metlife Quote Funnel" class="btn-vertical" color="black" v-on:click="closeWindow('https://www.metlifepetinsurance.com/pet-insurance')" ref="exitButtonFooter"> 
                    <v-icon>mdi-close</v-icon>  
                    <span>Exit</span>
                </v-btn>
            </v-col>
            <v-divider role="none" ></v-divider>
            <div class=" mt-6 page-width main-footer">
                <v-row class="footer-boxes footer-links">
                    <v-col cols="12" lg="2" class="footer-box order-0">
                        <div class="footer-socials">
                            <div class="hide-on-mobile" role="group" aria-label="Social Media Links">
                                <div class="social-stacker">
                                    <a tabindex="0" role="link" href="#" v-on:click="redirectToOtherLink('https://www.instagram.com/metlifepetinsurance/')" v-on:keyup.enter="redirectToOtherLink('https://www.instagram.com/metlifepetinsurance/')" aria-label="Follow us on Instagram"  target="_blank">
                                        <v-icon aria-hidden="true" x-large>mdi-instagram</v-icon>
                                    </a>
                                    <a tabindex="0" role="link" href="#" v-on:click="redirectToOtherLink('https://www.facebook.com/MetLifePetInsurance/')" v-on:keyup.enter="redirectToOtherLink('https://www.facebook.com/MetLifePetInsurance/')" aria-label="Like us on Facebook" target="_blank">
                                        <v-icon aria-hidden="true" x-large>mdi-facebook</v-icon>
                                    </a>
                                </div>
                                <div class="social-stacker">
                                    <a tabindex="0" role="link" href="#" v-on:click="redirectToOtherLink('https://twitter.com/MetLife_Pet')" v-on:keyup.enter="redirectToOtherLink('https://twitter.com/MetLife_Pet')" aria-label="Follow us on Twitter" target="_blank">
                                        <v-icon aria-hidden="true" x-large>mdi-twitter</v-icon>
                                    </a> 
                                    <a tabindex="0" role="link" href="#" v-on:click="redirectToOtherLink('https://www.pinterest.com/metlifepetinsurance/')" v-on:keyup.enter="redirectToOtherLink('https://www.pinterest.com/metlifepetinsurance/')"  aria-label="Follow us on Pinterest" target="_blank">
                                         <v-icon aria-hidden="true" x-large>mdi-pinterest</v-icon>
                                    </a>
                                </div>
                            </div>
                            <!-- <div class="hide-above-mobile">
                                <div class="social-stacker">
                                    <a v-on:click="redirectToOtherLink('https://www.instagram.com/metlifepetinsurance/')" v-on:keyup.enter="redirectToOtherLink('https://www.instagram.com/metlifepetinsurance/')" title="Follow us on Instagram" target="_blank"><i class="fa fa-instagram" aria-hidden="true" tabindex="-1"></i></a>
                                    <a v-on:click="redirectToOtherLink('https://www.facebook.com/MetLifePetInsurance/')" title="Like us on Facebook" target="_blank"><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                                    <a v-on:click="redirectToOtherLink('https://twitter.com/MetLife_Pet')" title="Follow us on Twitter" target="_blank"><i class="fa fa-twitter-square" aria-hidden="true"></i></a>
                                    <a v-on:click="redirectToOtherLink('https://www.pinterest.com/metlifepetinsurance/')" title="Follow us on Pinterest" target="_blank"><i class="fa fa-pinterest-square" aria-hidden="true"></i></a>
                                </div>
                            </div> -->
                        </div>
                    </v-col> 
                    <!-- <div class="footer-box hide-on-mobile">
                        <ul>
                            <li><a v-on:click="redirectToOtherLink('https://www.petfirst.com/pet-insurance')" title="Pet Insurance">Pet Insurance</a></li> 
                            <li><a v-on:click="redirectToOtherLink('https://www.petfirst.com/pet-insurance-resources')" title="Resources">Resources</a></li>
                            <li><a v-on:click="redirectToOtherLink('https://www.petfirst.com/blog')" title="Blog">Blog</a></li>
                            <li><a v-on:click="redirectToOtherLink('https://mypets.petfirsthealthcare.com/Account/Login?ReturnUrl=%2F')" title="MyPets Login">MyPets Login</a></li>
                        </ul>
                    </div>
                    <div class="footer-box hide-on-mobile">
                        <ul>
                            <li><a v-on:click="redirectToOtherLink('https://www.petfirst.com/about-us')" title="About Us">About Us</a></li>
                            <li><a v-on:click="redirectToOtherLink('https://www.petfirst.com/about-us/our-team/')" title="Team">Team</a></li>
                            <li><a v-on:click="redirectToOtherLink('https://www.petfirst.com/about-us/careers/')" title="Careers">Careers</a></li>
                        </ul>
                    </div>
                    <div class="footer-box hide-on-mobile">
                        <h6>Quick Links</h6>
                            <ul>
                                <li><a v-on:click="redirectToOtherLink('https://www.petfirst.com/customer-support')" title="Customer Support Center">Customer Support Center</a></li>
                                <li><a v-on:click="redirectToOtherLink('https://www.petfirst.com/pet-insurance/how-to-file-a-claim#submit-claim')" title="Submit a Claim">Submit a Claim</a></li>
                                <li><a v-on:click="redirectToOtherLink('https://www.petfirst.com/pet-insurance/how-to-file-a-claim')" title="How to File a Claim">How to File a Claim</a></li>
                                <li><a v-on:click="redirectToOtherLink('https://www.petfirst.com/pet-insurance-resources/frequently-asked-questions')" title="FAQs">FAQs</a></li>
                            </ul>
                    </div>  -->
                    <v-col cols="12" lg="2" class="footer-box order-2">
                        <h6 role="none">Mail</h6> 
                        <a v-if="currentQuoteFunnel !== null && currentQuoteFunnel.MailingAddress !== null"  tabindex="0" href="#" role="presentation" target="_blank" class="nobold blueFooter"><div>MetLife Pet Insurance</div> <div>{{currentQuoteFunnel.MailingAddress}}</div></a> 
                        <a v-else v-on:click="redirectToOtherLink('https://goo.gl/maps/wVdkk9irJL92')"  target="_blank" class="nobold blueFooter" href="#"><div>MetLife Pet Insurance</div> <div>400 Missouri Avenue </div> <div>Jeffersonville, IN 47130 </div></a>
                    </v-col>
                    <v-col cols="12" lg="3" class="footer-box order-1" tabindex="-1">
                        <h6 role="none">Call Center and Live Chat Hours</h6>
                        <div v-if="currentQuoteFunnel !== null && currentQuoteFunnel.CallCenterHours !== null">{{currentQuoteFunnel.CallCenterHours}}</div>
                        <div v-if="String(this.partnerId) === '251072'">
                            <div>Monday-Friday, 5am-6pm PST</div>
                            <div > Saturday-Sunday, 7am-4pm PST</div>
                        </div>
                        <div v-else>
                            <div>Monday-Friday, 8am-9pm EST</div>
                            <div > Saturday-Sunday, 10am-7pm EST</div>
                        </div>
                        <div class="footer-phone-numbers pb-0">
                            <div v-if="currentQuoteFunnel !== null && currentQuoteFunnel.PhoneNumber !== null" class="footer-phone">
                                <p class="fs-16">Phone</p> 
                                <a>{{currentQuoteFunnel.PhoneNumber}}</a>
                            </div>
                            <div v-else-if="partnerId !== undefined" class="footer-phone">
                                <p class="fs-16">Phone</p> 
                                <a  class="blueFooter" :aria-label="'Call number '+ this.getPartnerContactInfo() +' now'" :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEventPhone()"> {{this.getPartnerContactInfo()}} </a>
                            </div>
                            <div v-else class="footer-phone">
                                <p class="fs-16">Phone</p> 
                                <a  class="blueFooter" :aria-label="'Call number '+ this.getPartnerContactInfo() +' now'" :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEventPhone()"> {{this.getPartnerContactInfo()}} </a>
                            </div>
                            <div v-if="currentQuoteFunnel !== null && currentQuoteFunnel.FaxNumber !== null" class="footer-fax">
                                <p class="fs-16">Fax</p> 
                                <p>{{currentQuoteFunnel.FaxNumber}}</p>
                            </div>
                            <div v-else class="footer-fax">
                                <p class="fs-16">Fax</p> 
                                <p>877.281.3348</p>
                            </div>
                        </div>
                    </v-col>
                </v-row> 
            </div>
        </v-container>
        <v-container fluid class="pt-0">
            <v-row class="d-flex justify-center">
                <v-col class="pa-0 text-lg-left text-center" lg="3" cols="12">
                    <div class="mt-0 mb-8">
                        <p>Si desea un servicio de atención al cliente multilingüe, llame al 1-800-GET-MET8 y solicite un traductor.</p>
                    </div>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center" v-if="enrollmentCode !== '99-99-00-0002'">
                <v-col class="pa-0 text-lg-left text-center" lg="3" cols="12">
                    <v-btn :block="$vuetify.breakpoint.lgAndUp" color="primary" x-large class="white--text btn-wrap" v-on:click="redirectToPFSupport()">
                            Contact Customer Support                
                    </v-btn>
                </v-col>
            </v-row>
                    
            <div>
                <nav class="margin-top-bottom text-center regular">
                    <ul aria-label="Legal Information.">
                        <li>
                            <a 
                                href="javascript:void(0)" 
                                class="blueFooter"  
                                v-on:click="redirectToOtherLink(dynamicContent && dynamicContent.companyTermsAndConditionsLink ? dynamicContent.companyTermsAndConditionsLink : defaultDynamicContent.companyTermsAndConditionsLink)" 
                                v-on:keyup.enter="redirectToOtherLink(dynamicContent && dynamicContent.companyTermsAndConditionsLink ? dynamicContent.companyTermsAndConditionsLink : defaultDynamicContent.companyTermsAndConditionsLink)"
                            >
                                {{ companyTermsAndConditionsLinkText }}
                            </a>
                        </li> 
                        <v-divider role="none" aria-hidden="true" light vertical class="black--text mx-1 d-inline" v-if="companyTermsAndConditionsLinkText && companyPrivacyPolicyLinkText"></v-divider>
                        <li>
                            <a 
                                href="javascript:void(0)" 
                                class="blueFooter" 
                                v-on:click="redirectToOtherLink(dynamicContent && dynamicContent.companyPrivacyPolicyLink ? dynamicContent.companyPrivacyPolicyLink : defaultDynamicContent.companyPrivacyPolicyLink)" 
                                v-on:keyup.enter="redirectToOtherLink(dynamicContent && dynamicContent.companyPrivacyPolicyLink ? dynamicContent.companyPrivacyPolicyLink : defaultDynamicContent.companyPrivacyPolicyLink)"
                            >
                                {{ companyPrivacyPolicyLinkText }}
                            </a>
                        </li>
                    </ul>
                </nav>
                <div class="margin-top-bottom text-center copyright-info" v-if="currentQuoteFunnel !== null">
                    <p>© <span id="current_year"> {{ new Date().getFullYear() }} </span> MetLife Pet Insurance<br></p>

                    <p>© <span id="current_year"> {{ new Date().getFullYear() }} </span> Peanuts Worldwide LLC<br></p>
                    <p class="footnote" v-if="currentQuoteFunnel && currentQuoteFunnel.LegalFooter !== null">{{currentQuoteFunnel.LegalFooter}}</p>
                </div>
                <div class="margin-top-bottom text-center copyright-info" v-else>
                    <p>© <span id="current_year"> {{ new Date().getFullYear() }} </span> MetLife Pet Insurance<br></p>

                    <p>© <span id="current_year"> {{ new Date().getFullYear() }} </span>  Peanuts Worldwide LLC<br></p>
                    <p class="footnote" v-if="currentQuoteFunnel && currentQuoteFunnel.LegalFooter !== null">{{currentQuoteFunnel.LegalFooter}}</p>
                </div>
                <div class="margin-top-bottom text-center copyright-info">
                    <p class="footnotefirst">L0922026203[exp0924][All States][DC,GU,MP,PR,VI]</p>                  
                    <p 
                        id="footnote-1" 
                        ref="footnote1"  
                        class="footnote text-left" 
                        tabindex="-1"
                        @keyup.tab="goToElementAndFocus('anchorFootnote1', 'anchorFootnote1')"
                        v-html="companyFooterFootnote1Html"
                    >
                    </p>
                    <p 
                        id="footnote-2" 
                        ref="footnote2" 
                        class="footnote text-left" 
                        tabindex="-1"
                        @keyup.tab="goToElementAndFocus('anchorFootnote2', 'anchorFootnote2')"
                        v-html="companyFooterFootnote2Html"
                    > 
                    </p>
                    <p 
                        id="footnote-3" 
                        ref="footnote3" 
                        class="footnote text-left" 
                        tabindex="-1"
                        @keyup.tab="goToElementAndFocus('anchorFootnote3', 'anchorFootnote3')"
                        v-html="companyFooterFootnote3Html"
                    >
                    </p>
                    <p 
                        id="footnote-4"  
                        ref="footnote4" 
                        class="footnote text-left" 
                        tabindex="-1"
                        @keyup.tab="goToElementAndFocus('anchorFootnote4', 'anchorFootnote4')"
                        v-html="companyFooterFootnote4Html"
                    > 
                    </p>
                    <p 
                        id="footnote-5"  
                        ref="footnote5" 
                        class="footnote text-left" 
                        tabindex="-1"
                        @keyup.tab="goToElementAndFocus('anchorFootnote5', 'anchorFootnote5')"
                        v-html="companyFooterFootnote5Html"
                        v-if="enrollmentCode !== '99-99-00-0002' && isValidPartnerId"
                    >
                    </p>
                    <p 
                        id="footnote-5"  
                        ref="footnote5" 
                        class="footnote text-left" 
                        tabindex="-1"
                        @keyup.tab="goToElementAndFocus('anchorFootnote5', 'anchorFootnote5')"
                        v-else-if="enrollmentCode === '99-99-00-0002'"
                    >
                        <b>5</b>  In MN, Discount is called an “Employee Affinity Discount”
                    </p>

                    
                    <p class="footnote" v-if="showDisclaimerCopyAffiliates()">
                        “Plans can start at just $29/Coverage for about $1 a day.” Calculation based upon select plans and coverages. Actual rates may vary based on pet age, breed, location, deductible, reimbursement rate, and annual limits you choose. For IAIC policies, premium is also based upon pet gender.
                    </p>
                    <p class="footnote" v-if="showDisclaimerCopyAffiliates()">
                        “Reimbursement for up to 90% of vet costs.” Reimbursement options include: 70%, 80% and 90% and a 50% option for MetGen policies and a 65% option for IAIC policies only. Pet age restrictions may apply.    
                    </p>
                    <p class="footnote" v-if="showDisclaimerCopyAffiliates()">
                        “Optional preventive care coverage for routine wellness needs.” For IAIC policies, optional Preventive Care coverage is based on a Schedule of Benefits. For MetGen policies, optional Preventive Care coverage is included in the annual limit.
                    </p>

                    <p 
                        v-for="(footnote, index) in companyAdditionalFooterFootnotesHtml" 
                        :key="index"
                        :id="'footnote-' + (index + 6)"  
                        :ref="'footnote' + (index + 6)" 
                        class="footnote text-left" 
                        tabindex="-1"
                        @keyup.tab="goToElementAndFocus('anchorFootnote' + (index + 6), 'anchorFootnote' + (index + 6))"
                        v-html="footnote"
                    ></p>
                </div>
            </div>
        </v-container>

    </footer>
    
</template>
<script>
    import CoverageDialog from './CoverageDialog.vue';
    import Swal from 'sweetalert2';

    export default {
        name: "globalFooter",
        components: {
            CoverageDialog
        },
        computed: {
            partnerId() {
                return this.$store.getters.partnerId;
            },
            partner() {
                return this.$store.getters.partner;
            },
            enrollmentCode() {
                return this.$store.getters.enrollmentCode;
            },
            currentQuoteFunnel(){
                return this.$store.getters.currentQuoteFunnel;
            },
            defaultDynamicContent() {
                return this.$store.getters.getDefaultDynamicContent.DynamicJson;
            },
            dynamicContent() {
                return this.$store.getters.getDynamicContent.DynamicJson;
            },
            companyTermsAndConditionsLinkText(){
                return this.defaultDynamicContent && this.defaultDynamicContent.companyTermsAndConditionsLinkText 
                    ? this.dynamicContent && this.dynamicContent.companyTermsAndConditionsLinkText 
                        ? this.dynamicContent.companyTermsAndConditionsLinkText 
                        : this.defaultDynamicContent.companyTermsAndConditionsLinkText 
                    : "";
            },
            companyPrivacyPolicyLinkText(){
                return this.defaultDynamicContent && this.defaultDynamicContent.companyPrivacyPolicyLinkText  
                    ? this.dynamicContent && this.dynamicContent.companyPrivacyPolicyLinkText 
                        ? this.dynamicContent.companyPrivacyPolicyLinkText 
                        : this.defaultDynamicContent.companyPrivacyPolicyLinkText 
                    : "";
            },
            companyFooterFootnote1Html(){
                return this.defaultDynamicContent && this.defaultDynamicContent.companyFooterFootnote1Html 
                    ? this.dynamicContent && this.dynamicContent.companyFooterFootnote1Html 
                        ? this.dynamicContent.companyFooterFootnote1Html 
                        : this.defaultDynamicContent.companyFooterFootnote1Html 
                    : '';
            },
            companyFooterFootnote2Html(){
                return this.defaultDynamicContent && this.defaultDynamicContent.companyFooterFootnote2Html 
                    ? this.dynamicContent && this.dynamicContent.companyFooterFootnote2Html 
                        ? this.dynamicContent.companyFooterFootnote2Html 
                        : this.defaultDynamicContent.companyFooterFootnote2Html 
                    : '';
            },
            companyFooterFootnote3Html(){
                return this.defaultDynamicContent && this.defaultDynamicContent.companyFooterFootnote3Html 
                    ? this.dynamicContent && this.dynamicContent.companyFooterFootnote3Html 
                        ? this.dynamicContent.companyFooterFootnote3Html 
                        : this.defaultDynamicContent.companyFooterFootnote3Html 
                    : '';
            },
            companyFooterFootnote4Html(){
                return this.defaultDynamicContent && this.defaultDynamicContent.companyFooterFootnote4Html 
                    ? this.dynamicContent && this.dynamicContent.companyFooterFootnote4Html 
                        ? this.dynamicContent.companyFooterFootnote4Html 
                        : this.defaultDynamicContent.companyFooterFootnote4Html 
                    : '';
            },
            companyFooterFootnote5Html(){
                return this.defaultDynamicContent && this.defaultDynamicContent.companyFooterFootnote5Html 
                    ? this.dynamicContent && this.dynamicContent.companyFooterFootnote5Html 
                        ? this.dynamicContent.companyFooterFootnote5Html 
                        : this.defaultDynamicContent.companyFooterFootnote5Html 
                    : '';
            },
            companyAdditionalFooterFootnotesHtml(){
                return this.defaultDynamicContent && this.defaultDynamicContent.companyAdditionalFooterFootnotesHtml 
                    ? this.defaultDynamicContent.companyAdditionalFooterFootnotesHtml 
                    : [];
            },
            isValidPartnerId(){
                return this.partnerId !== null && this.partnerId !== undefined;
            }
        },
        props: {
            onClose: Function
        },
        methods: {
            redirectToPFSupport() {
                var ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: 'Contact Customer Support',
                        stepName: 'Pet Info'
                    }
                }
                digitalData.eventTrack('linkClick', ldo);

                this.$store.dispatch("analyticsExit");
                
                sessionStorage.clear();
                this.$store.reset();
                
                window.location.href = 'https://metlifepetinsurance.com/customer-support/';
            },
            async redirectToOtherLink(url){
                await Swal.fire({
                    title: "You are about to open an URL in a New Tab, Proceed to new tab?",
                    showDenyButton: true, 
                    confirmButtonText: "Yes",
                    denyButtonText: "No",
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch("analyticsExit");
                        window.open(url);
                    }
                })
            },
            async closeWindow(url) {
                this.onClose("footer");
            },
            callCenterNum() {
                var ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: 'Phone Number: 866.937.7387',
                        stepName: 'Pet Info'
                    }
                }
                digitalData.eventTrack('linkClick', ldo);
            }
        }
    }
</script>

<style>
.footer-phone-numbers div > a,
.footer-phone-numbers div > p  {
    min-width: 105px;
}
</style>
